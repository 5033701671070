import React from 'react';
import { Helmet } from 'react-helmet';
interface Props {
	meta_title: string;
	meta_description: string;
}

const SEO: React.FunctionComponent<Props> = ({ meta_title, meta_description }) => {
	return (
		<Helmet>
			<title>{meta_title}</title>
			<meta name={'description'} content={meta_description}/>
			<html lang={'en'}/>
		</Helmet>
	);
};

export default SEO;
