import React from 'react';

import { Container } from 'components/Container';
import { Article } from '../apiFolder/main';
import SEO from '../components/Seo';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';

interface Context {
	pageContext: {
		item: Article;
	};
}

const testTemplate: React.FunctionComponent<Context> = ({ pageContext: { item } }) => {
	const getDate = (created: number) => {
		const date = new Date(created);
		const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
		const month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
		return `${day}.${month}.${date.getFullYear()}`;
	};
	return (
		<Container>
			<SEO meta_description={item.meta_description} meta_title={item.meta_title} />
			<h5 style={{ borderBottom: '1px solid #dddddd' }}>
				<a href={'/'}>Back</a>
			</h5>
			<ArticleHeader>{item.title}</ArticleHeader>
			<ArticleDate>Created {item.created_at && getDate(item.created_at)}</ArticleDate>
			{item.url && <ImageContainer url={item.url} />}
			<ArticleContent>
				<ReactMarkdown>{item.content}</ReactMarkdown>
			</ArticleContent>
		</Container>
	);
};

const ArticleHeader = styled.div`
	margin-top: 80px;
	font-size: 64px;
	font-weight: 700;
`;
const ArticleDate = styled.div`
	font-size: 20px;
	font-weight: 400;
	color: #5f6c80;
	margin-bottom: 60px;
`;
const ArticleContent = styled.div`
	font-size: 20px;
	font-weight: 400;
	margin-top: 40px;
	margin-bottom: 300px;
`;
const ImageContainer = styled.div<{ url: string | undefined }>`
	margin-left: 10px;
	background: url(${({ url }) => (url ? url : 'https://via.placeholder.com/150x150')}) 50% 50% no-repeat;
	width: 150px;
	background-size: cover;
	border-radius: 10px;
`;

export default testTemplate;
